.post {
    width: 100%;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
    box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
    margin: 30px 0;
  }
  
  .postWrapper {
    padding: 10px;
  }
  
  .postTop {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .postTopLeft {
    display: flex;
    align-items: center;
  }
  
  .postProfileImg {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .postUsername {
    font-size: 15px;
    font-weight: 500;
    margin: 0 10px;
  }
  
  .postDate{
      font-size: 12px;
  }
  
  .postCenter{
      margin: 20px 0;
  }
  
  .postImg{
      margin-top: 20px;
      width: 100%;
      max-height: 500px;
      object-fit: contain;
  }
  
  .postBottom{
      display: flex;
      align-items: center;
      justify-content: space-between;
  }
  
  .postBottomLeft {
    display: flex;
    align-items: center;
    padding: 7px;
    border-radius: 20px 0px 20px 0px; /* Alternating rounded corners */
    /* background: linear-gradient(to left, #e3f2fd, #bbdefb); Light blue gradient */
    border: 2px solid #90caf9; /* Soft blue border */
    font-weight: bold;
    font-style: italic;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* More pronounced shadow */
  }
  
  .likeIcon{
      width: 24px;
      height: 24px;
      margin-right: 5px;
      cursor: pointer;
  }
  
  .postLikeCounter{
      font-size: 15px;
  }
  
  .postCommentText{
      cursor: pointer;
      border-bottom: 1px dashed gray;
      font-size: 15px;
  }

  .postDescription {
    margin-left: 20px;
    margin-right: 20px;
    font-size: 14px; /* Adjust the font size as needed */
    color: #333; /* Optional: change the text color */
  }