.feed {
    flex: 5.5
}

.feedWrapper {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 8px;
}

.feedWrapper > *:not(:last-child) {
    margin-bottom: 10px;
}

.gap {
    height: 20px;
}