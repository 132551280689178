.underline {
  text-decoration: underline;
  font-size: 1.3rem;
}

.qna-parent-container {
  font-size: 25px;
  padding: 0px 16px;
  width: 80vw;
  margin-left: 10vw;
  font-family: "Dongle", sans-serif;
}

.question {
  font-weight: 700;
}
.icon {
  display: inline;
}

.input {
  display: inline;
}

.button-54 {
  font-size: 16px;
  letter-spacing: 2px;
  text-decoration: none;
  text-transform: uppercase;
  color: #000;
  cursor: pointer;
  border: 3px solid;
  padding: 0.25em 0.5em;
  box-shadow: 1px 1px 0px 0px, 2px 2px 0px 0px, 3px 3px 0px 0px, 4px 4px 0px 0px,
    5px 5px 0px 0px;
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  margin-left: 30px;
}

.button-54:active {
  box-shadow: 0px 0px 0px 0px;
  top: 5px;
  left: 5px;
}

@media (min-width: 768px) {
  .button-54 {
    padding: 0.25em 0.75em;
  }
}

.input > div {
  margin-top: 20px;
}
.stay-a {
  margin-left: 58%;
}
.input label {
  font-size: large;
  margin-left: 8px;
}
.textbox {
  margin-left: 30px;
  height: 50px;
  width: 80%;
  border: 1.5px solid black;
}
.main-container {
  margin-left: 13%;
}

.checkbox-rect2 input[type="checkbox"] {
  display: none;
}
.checkbox-rect2 input[type="checkbox"] + label {
  display: inline;
  position: relative;
  padding-left: 35px;
  margin-bottom: 20px;
  font: 14px/20px "Open Sans", Arial, sans-serif;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.checkbox-rect2 input[type="checkbox"]:hover + label:before {
  border: 1px solid #343a3f;
  box-shadow: 2px 1px 0 #343a3f;
}
.checkbox-rect2 input[type="checkbox"] + label:last-child {
  margin-bottom: 0;
}
.checkbox-rect2 input[type="checkbox"] + label:before {
  content: "";
  display: inline;
  width: 1.4em;
  height: 1.4em;
  border: 1px solid #343a3f;
  border-radius: 0.2em;
  position: absolute;
  left: 0;
  top: 0;
  -webkit-transition: all 0.2s, background 0.2s ease-in-out;
  transition: all 0.2s, background 0.2s ease-in-out;
  background: rgba(255, 255, 255, 0.03);
  box-shadow: -2px -1px 0 #343a3f;
  background: #f3f3f3;
}
.checkbox-rect2 input[type="checkbox"]:checked + label:before {
  border: 2px solid #fff;
  border-radius: 0.3em;
  background: #50565a;
  box-shadow: 2px 1px 0 #50565a;
}

.stay-a {
  margin-left: 58%;
}

.qna-content-container {
  padding: 0px 50px;
}
