.user-details-parent-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.2);
}
.user-details-header {
  display: flex;
  justify-content: flex-end;
}
.user-details-close {
  height: 35px;
  padding: 2px;
  padding-top: 10px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 35px;
  cursor: pointer;
}

.user-info-content {
  padding: 8px;
  height: calc(100% - 25px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
