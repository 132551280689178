.sq_redirect {
  height: 90vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("../../../assets/img/pu33.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
.sq_redirect .sq-child {
  width: 100%;
  padding: 15px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(63, 51, 51, 0.8);
}
.sq_redirect .sq-child h1 {
  font-size: 75px;
  opacity: 1;
  text-shadow: 0px 4px 3px rgba(0, 0, 0, 0.4), 0px 6px 9px rgba(0, 0, 0, 0.1),
    0px 9px 13px rgba(0, 0, 0, 0.1);
}
#goto {
  opacity: 1 !important;
  padding: 10px 25px;
  width: 150px;
  border: solid 2px #f37df3;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  color: aliceblue;
  font-weight: 600;
}
#goto:hover {
  color: black;
  background-color: #f37df3;
  transition: background-color 0.3s ease-out;
}
