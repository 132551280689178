.shareCard {
  width: 95%;
  margin: 20px auto;
  padding: 10px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
}

.shareWrapper {
  display: flex;
  flex-direction: column;
  background-color: #f4f4f4;
  border-radius: 8px;
  padding: 20px;
}

.shareInput {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  margin: 10px 0;
  width: 100%;
}

.shareInput:focus {
  outline: none;
  border-color: #007bff;
}

.shareOptions {
  margin-top: 20px;
}

.imageUpload {
  margin-top: 10px;
}

.imagePreview {
  height: 100px;
  margin-top: 10px;
}

.shareButton {
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  font-weight: 500;
  cursor: pointer;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.shareButton:hover {
  background-color: #0056b3;
}

.errorMsg {
  color: red;
  font-size: 0.9rem;
  margin-top: 10px;
}

.successMsg {
  color: green;
  font-size: 0.9rem;
  margin-top: 10px;
  display: flex;
  align-items: center;
}
