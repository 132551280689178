.CardHolder{
    display: flex;
    min-height: 75vh;
    justify-content: center;
    flex-wrap: wrap;
    font-family: Quicksand,sans-serif;
    margin-right: auto;
    padding: 30px 5px 5px;
    width: 100%;
}

.schemeCard {
    align-items: center;
    background-color: inherit;
    box-shadow: 3px 4px 4px 2px #066797c3;
    border-radius: 5px;
    display: flex;
    flex: 0 0 300px;
    flex-direction: column;
    margin: 15px;
    padding: 3px;
    width: 42vh;
}

.schemeCard :hover{
    /* border-color: #0667970a;
    border-width: 6px; */
    border-radius: 5px;
}

.cardImage {
    /* border: 0.5px solid #023047; */
    border-radius: 50px;
    height: 28vh;
    width: 40vh;
    margin: 5px 5px;
}

.schemeCard p {
    margin-bottom: 5px;
}

.MakeBackgroundBlur {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.schemeDetailBox {
    background: white;
    height: 70vh;
    width: 90vw;
    margin: auto;
    padding: 1%;
    border: 2px solid #000;
    border-radius: 10px;
    box-shadow: 2px 2px black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.textarea {
    height: 30vh;
    width: 80vw;
    overflow: scroll;
}

.textarea::-webkit-scrollbar {
    width: 0.2em;
}

.textarea::-webkit-scrollbar-track {
    background-color: rgba(220, 217, 217, 0.137);
}

.textarea::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

@media (max-width: 650px) {
    .cardImage {
        height: 35vw;
        width: 43vw;
    }
}