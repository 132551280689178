.auth-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

/* Image style */
.profile-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #ffb703;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}