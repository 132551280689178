.welcome-slider {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.welcome-slider .dynamic-images {
    width: 43vw;
    height: 33vw;
    box-shadow: 5px 10px 12px rgb(94 125 115);
    transition: background-image 2s ease-in-out;
}

#intro .slider-logo {
  background-color: #ffc107;
  border-radius: 10px;
  width: 7vw;
  margin-right: 5px;
  margin-left: var(--bs-slider-logo-margin-end);;
}

@media (max-width: 1080px) {
  .welcome-slider {
    flex: 1;
  }

  .welcome-slider .dynamic-images {
    height: 55vw;
    min-width: 90vw;
  }

  #intro .slider-logo {
    width: 11vw;
  }
}

/* Blockquote CSS */

@import url(https://fonts.googleapis.com/css?family=Open+Sans:400italic);
blockquote{
  font-size: 0.85vw;
  margin: 1rem !important;
  font-family:Open Sans;
  font-style:italic;
  color: #023047;
  padding:1.2em 30px 1.2em 75px;
  border-left:8px solid #023047 ;
  line-height:1.6;
  position: relative;
  background: #f4eac2;
}

blockquote::before{
  font-family:Arial;
  content: "\201C";
  color:#023047;
  font-size:4em;
  position: absolute;
  left: 10px;
  top:-10px;
}

blockquote::after{
  content: '';
}

blockquote span{
  display:block;
  color:#333333;
  font-style: normal;
  font-weight: bold;
  margin-top:1em;
}