/* CARDS */

.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.card {
  margin: 20px;
  padding: 20px;
  width: -webkit-fill-available;
  min-height: 150px;
  display: grid;
  grid-template-rows: 0px 0px 1fr 50px;
  border-radius: 10px;
  box-shadow: 0px 6px 10px #023047;
  transition: all 0.2s;
  background: radial-gradient(#f7da90, #ffb703);
}

.card:hover {
  box-shadow: 0px 6px 10px #023047;
  transform: scale(1.01);
}

.card__link,
.card__exit,
.card__icon {
  position: relative;
  text-decoration: none;
  color: #023047;
  padding: 3px;
  border-radius: 7px;
  box-shadow: 0px 6px 10px #023047;
}

.card__link::after {
  position: absolute;
  top: 25px;
  left: 0;
  content: "";
  width: 0%;
  height: 3px;
  background-color: rgba(37, 64, 122, 0.9);
  transition: all 0.5s;
}

.card__link:hover::after {
  width: 100%;
}

.card__exit {
  grid-row: 1/2;
  justify-self: end;
}

.card__icon {
  grid-row: 2/3;
  font-size: 30px;
}

.card__title {
  grid-row: 3/4;
  font-weight: 400;
  color: #023047;
}

.card__apply {
  grid-row: 4/5;
  align-self: center;
}

/* RESPONSIVE */

@media (max-width: 1600px) {
  .cards {
    justify-content: center;
  }
}
