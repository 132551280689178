.event-admin-panel {
  display: flex;
  width: 80vw;
  margin: auto;
  padding: 5px;
  justify-content: center;
  min-height: 55vh;
}
.event-admin-panel .controls {
  display: flex;
  flex: 1 1 150px;
  flex-direction: column;
  background-color: rgb(142, 202, 230);
  padding: 5px;
}
.event-admin-panel .controls .event-control-button {
  margin: 8px 5px;
  font-size: 1em;
  font-family: "Ubuntu", sans-serif;
  text-transform: uppercase;
  cursor: pointer;
  text-align: center;
  padding: 5px 0;
  box-shadow: 0px 1px 10px #023047;
  
}
.event-controls {
  padding: 6px;
}
.events-showcase {
  width: 100%;
}
.events-showcase h5 {
  text-align: center;
}
.event-admin-panel .controls .event-control-button:hover {
  color: azure;
  background-color: rgb(35, 101, 155);
  transition: all 0.3s ease-in;
}
.event-admin-panel .view {
  /* height: 80vh; */
  /* overflow-y: scroll; */
  padding: 10px;
  display: flex;
  flex: 2 1 500px;
  background-color: #fff0ca;
}
.event-cards-container {
  font-family: "Quicksand", sans-serif;
  width: 100%;
}
.event-card {
  background-color: inherit;
  border-bottom: cyan;
}
.view #addEventForm {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  margin: auto;
  padding: 5px 20px;
  font-family: "Quicksand", sans-serif;
}
.view #addEventForm .field-div {
  align-items: baseline;
}
.view #addEventForm .field-div .addEventLabels {
  flex: 2 1 150px;
  flex-wrap: nowrap;
}
#addEventSubmit,
#editEventSubmit,
.EventCancel {
  width: 300px;
  margin: 15px auto;
}

#eventImg {
  display: none;
}
label[htmlFor="eventImg"] {
  content: url("../../../../assets/img/plus.svg");
  display: inline-block;
  transform: scale(0.5);
  cursor: pointer;
  width: 100px;
  height: 100px;
  transition: transform 0.3s ease-in;
}
label[htmlFor="eventImg"]:hover {
  transform: scale(0.52);
  transition: transform 0.3s ease-in;
}
#extraImagesDiv {
  display: flex;
  align-items: center;
}
#extraImagesDiv label {
  cursor: pointer;
}
#extraImagesDiv button {
  border-radius: 5px;
  padding: 5px !important;
  border: 1px solid #023047;
  transition: all 0.3s ease-in;
  box-shadow: 0px 1px 10px #023047;
}

#multiImg {
  overflow-y: hidden !important;
  overflow-x: scroll;
}

/* Add events */
.event-add-image-preview {
  height: 200px;
  width: 200px;
  border: 1px solid #f8ba1f;
  align-self: flex-end;
  margin: 10px 0px 0px;
}

@media (max-width: 900px) {
  .event-admin-panel {
    flex-direction: column;
  }

  .event-admin-panel .controls {
    display: flex;
    flex-direction: column;
    max-height: 200px;
    min-height: 100px;
    background-color: rgb(142, 202, 230);
    padding: 5px;
  }
}